/* App.css */
.app-container {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #333;
  margin-top: 50px;
}

.rating-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 10px 30px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  user-select: none;
}

.rating-button {
  background-color: #f7f7f7;
  border: none;
  padding: 8px 12px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rating-button:hover {
  background-color: #e0e0e0;
}

.rating-button:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.rating-value {
  font-size: 1.5em;
  min-width: 50px;
  text-align: center;
}

/* Optional: Add a smooth transition to the rating value changes */
.rating-value {
  transition: transform 0.2s ease;
}

.rating-value-change-enter {
  transform: scale(1.5);
}

.rating-value-change-enter-active {
  transform: scale(1);
}

.rating-value-change-exit {
  transform: scale(1);
}

.rating-value-change-exit-active {
  transform: scale(0.5);
}

.rating-message {
  margin-top: 20px;
  font-size: 1.2em;
  color: #666;
  transition: opacity 0.3s ease;
}